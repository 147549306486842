import { useTranslation } from "react-i18next";

import SectionHeading from "../../common/SectionHeading";
import SingleSolution from "./SingleSolution";
import Users03 from "@untitled-ui/icons-react/build/cjs/Users03";
import ActivityHeart from "@untitled-ui/icons-react/build/cjs/ActivityHeart";
import LineChartUp02 from "@untitled-ui/icons-react/build/cjs/LineChartUp02";
const SolutionSection = () => {
    const {t} = useTranslation();
    const problemIcons = [
        <Users03/>,
        <ActivityHeart/>,
        <LineChartUp02/>,
    ]
    const colors = [
        "#C24B36",
        "#2D939A",
        "#200A09"
    ]
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9 gray-section" id="solution-section">
            <div className="container">

            <SectionHeading 
                upperHeader={t('solution.upperHeader')}
                header={t('solution.header')}
                // subHeader={t('solution.subHeader')}
                />
            
            <section className="flex w-100 mt-[64px] gap-x-16 flex-col md:flex-row gap-y-16 color-red-600">
                {t('solution.solutions').map((item, key) => {
                    return (
                        <SingleSolution 
                        key={key}
                        header={item.header}
                        paragraph={item.text}
                        progress={item.progress}
                        curveText={item.paragraph}
                        circuralClass={key === 1 ? 'velaria':''}
                        circurallColor={colors[key]}
                        >{problemIcons[key]}</SingleSolution>
                    ) 
                })}
            </section>
        </div>
        </section>
    );
};

export default SolutionSection;