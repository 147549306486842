import Text from "../../common/Text";

const SingleTeamMember = ({name, position, image, link}) => {
    return (
        <li className="">
            <div className="h-[240px] md:h-[400px] overflow-hidden relative">
                <img 
                    src={`/img/team/${name}.jpg`} 
                    className="w-full h-full object-cover object-top rounded-[8px]" 
                    alt={name}
                />
                <a href={link} target="_blank">
                    <img 
                        loading="lazy" 
                        src={'/img/linkedin-icon.svg'} 
                        className="absolute bottom-2 right-2 cursor-pointer w-8 h-8"
                        alt="LinkedIn"
                    />
                </a>
            </div>
            <div className="mt-[20px]">
                <Text variant="span" className='font-size-text-xl font-semibold line-height-text-xl'>{name}</Text>
            </div>
            <Text variant="span" className='font-size-text-lg line-height-text-lg text-primary-500'>{position}</Text>
        </li>
    );
};

export default SingleTeamMember;