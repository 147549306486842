import { useTranslation } from "react-i18next";
import SectionHeading from "../../common/SectionHeading";
import SingleTeamMember from "./SingleTeamMember";

const TeamSection = () => {
    const {t} = useTranslation();
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9 flex flex-col" id='team-section'>
            <SectionHeading
                upperHeader={t('team.upperHeader')}
                header={t('team.header')}
                subHeader={t('team.subHeader')}
            />
            <ul className="pt-7 flex flex-row flex-wrap gap-y-10 md:gap-y-12 gap-x-4 grid-cols-2 grid md:grid-cols-3 lg:grid-cols-4  justify-between">
            
                {t('team.members').map((item, index)=> {
                    return (
                        <SingleTeamMember
                            key={index}
                            name={item.name}
                            position={item.position}
                            image={''}
                            link={item.link}
                        />
                    )
                })}
            </ul>
        </section>
    )
};

export default TeamSection;