import { useTranslation,Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Text from "./common/Text";
import { Field, Input, Label, Textarea, Checkbox } from '@headlessui/react'
import WrappedButton from "./common/Button";
import { useRef, useState } from "react";


const Footer = () => {
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [acceptetTC, setAcceptedTC] = useState(false);
    const [tcMessage, setTcMessage] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [areaMessage, setAreaMessage] = useState("");

    const handleSetMessage = (e) => {
       setMessage(e.target.value)
    }

    const handleSetEmail= (e) => {
        setEmail(e.target.value);
    }
    const validate = (email, message, tc) => { 
        let bool = true
        if(tc === false) {
            setTcMessage(t('footer.checkboxError'));
            bool = false;
        } else {
            setTcMessage("")
        }
        if(message.length < 1) {
            setAreaMessage(t('footer.textAreaError'));
            bool = false;
        } else {
            setAreaMessage("")
        }

        if(!String(email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                bool = false;
                setEmailMessage(t('footer.emailError'))
            }else {
            setEmailMessage("")

        }
        return bool;
    }

    const handleSendForm = (e) => {
        e.preventDefault();
        const payload = {
            from: email,
            message
        }
        const valid = validate(email, message, acceptetTC);

        if(email.length > 0 && message.length > 0 && acceptetTC === true && valid === true) {
             fetch(`/api/email`, {
                method: 'POST',
                body: JSON.stringify({ from: email, message: message })
            })
                //.then(res => res.json())
            setEmail("")
            setMessage("")
            setAcceptedTC(false)
        } else {        
        }
    }
    const emailRef = useRef(null);
    return (
        <>
        <div className="container">
            <form className="flex pb-9 flex-col md:flex-row gap-x-[32px] gap-y-[32px]" onSubmit={handleSendForm}>
                <div className="flex-1 relative">
                    <img src='/img/footer/footer-img.jpg'  className="w-full h-full object-cover rounded-[8px]"/>
                </div>
                <div className="flex-1 md:pt-[96px] md:pb-[96px]">
                    <Header variant="h3" className='font-semibold font-size-display-md line-height-display-md'>{t('footer.header')}</Header>
                    <Text className="mt-1">{t('footer.subHeader')}</Text>

                    <Field className='mb-0 pt-6 relative'>
                        <Label><Text className='font-size-text-sm font-medium line-height-text-sm'>{t('footer.emailLabel')}</Text></Label>
                        <Input invalid={false} ref={emailRef} value={email} onChange={handleSetEmail} placeholder={t('footer.emailPlaceholder')} type="text" name="full_name" 
                        className={`mt-1 pt-[10px] pb-[10px] pl-[14px] pr-[14px] border w-full input-base ${emailMessage.length > 0 ? 'validated-input-error':''}`} />
                         <div>
                            { emailMessage.length > 0 ? <Text variant='span' className={'  text-red-400 top;[32px'}>
                                    {emailMessage}
                                </Text>
                                :<Text variant='span' className={'text-transparent'}>asd</Text>}
                            </div>
                    </Field>

                    <Field className=''>
                    <Label><Text className={`font-size-text-sm font-medium line-height-text-sm`}>{t('footer.messageLabel')}</Text></Label>
                    <Textarea value={message} onChange={handleSetMessage} rows={5} placeholder={t('footer.messagePlaceholder')} type="text" name="full_name"
                         className={`mt-1 pt-[12px] pb-[12px] pl-[14px] pr-[14px] border w-full input-base resize-none ${areaMessage.length > 0 ? 'validated-input-error':''}`}/>
                    <div >

                    { areaMessage.length > 0 ? <Text variant='span' className={'  text-red-400 top;[32px'}>
                            {areaMessage}
                        </Text>
                        :<Text variant='span' className={'text-transparent'}>asd</Text>}
                    </div>
                    </Field>
                    <Field className='mb-4 flex gap-x-1 relative'>
                        <Checkbox checked={acceptetTC} onChange={setAcceptedTC}  className="group block size-4 rounded border bg-white w-[24px] h-[24px] self-center border-2" >
                            <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
                                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Checkbox>
                        { tcMessage.length > 0 && <Text variant='span' className={'absolute top-[20px] text-red-400'}>
                            {tcMessage}
                        </Text>}
                        
                        <Label className={'ml-[12px]'}>
                            <Text>
                                <Trans
                                i18nKey={t('footer.privacyPolicyLabel')}
                                components={{
                                    privacy: <Link style={{fontWeight:"bold"}} className="" to="/privacy-policy" target="_blank" onClick={() => window.open("/privacy-policy", '_blank') }>dasdasd</Link>
                                }}
                                />
                            </Text>
                            </Label>
                    </Field>
                    <WrappedButton variant={'button'} className="w-full bg-primary-500 link-button font-size-text-md line-height-text-md">{t('footer.sendButton')}</WrappedButton>
                </div>
            </form>
        </div>
        <footer className="pt-7 pb-5 bg-backgroundSecondary-400 mx-auto my-0 ">
            <div className="container hidden md:block">
                <section className="flex justify-between pb-6">
                    <img loading="lazy" src={'/img/docjo-icon.svg'} className="cursor-pointer logo"></img>
                    <nav className="flex md:gap-x-[20px] lg:gap-x-[32px]">
                        <Link className={"self-center cursor-pointer"} to="/#product-section">
                            <Text className='font-semibold font-size-text-md ' >
                            {t('navbar.solutions')}
                            </Text>
                        </Link>
                        <Link className={"self-center cursor-pointer"} to="/#certification-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.certifications')}
                            </Text>
                        </Link>
                        <Link className={"self-center cursor-pointer"} to="/#team-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.about')}
                            </Text>
                        </Link>
                        <Link className={"self-center cursor-pointer"} to="/#contact-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.contact')}
                            </Text>
                        </Link>
                        <Link className={"self-center cursor-pointer"} to="/privacy-policy">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.privacyPolicy')}
                            </Text>
                        </Link>
                        <Link className={"self-center cursor-pointer"}  to="/imprint">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.impressum')}
                            </Text>
                        </Link>
                    </nav>
                </section>
                <section className="pt-7 flex justify-between" style={{borderTop: '1px solid #E9ECF1'}}>
                    <Text className='font-size-text-md line-height-text-md'>&#169; {t("footer.rightsMessage")}</Text>
                    <a href="https://www.linkedin.com/company/docjo-health/">
                        <img loading="lazy" src={'/img/linkedin-icon.svg'} className="self-end cursor-pointer linkedin-icon"></img>
                    </a>
                </section>
            </div>
            <div className="container block md:hidden pt-7 pb-5 md:pb-6">
                <div className="flex justify-between">
                    <img loading="lazy" src={'/img/docjo-icon.svg'} className="cursor-pointer logo"></img>
                    <a href='https://www.linkedin.com/company/docjo-health/'>
                        <img loading="lazy" src={'/img/linkedin-icon.svg'} className="self-end cursor-pointer linkedin-icon"></img>
                    </a>

                </div>
                <div className="grid grid-cols-2 gap-y-[24px] gap-x-[8px] pt-4 pb-7 border-b border-solid border-borderFaq">
                <Link className={"cursor-pointer"} to="/#product-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.solutions')}
                            </Text>
                        </Link>
                        <Link className={"cursor-pointer"} to="/#certification-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.certifications')}
                            </Text>
                        </Link>
                        <Link className={"cursor-pointer"} to="/#team-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.about')}
                            </Text>
                        </Link>
                        <Link className={"cursor-pointer"} to="/#contact-section">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.contact')}
                            </Text>
                        </Link>
                        <Link className={"cursor-pointer"} to="/privacy-policy">
                            <Text className='font-semibold font-size-text-md overflow-hidden whitespace-nowrap text-ellipsis'>
                            {t('navbar.privacyPolicy')}
                            </Text>
                        </Link>
                        <Link className={"cursor-pointer"} to="/imprint">
                            <Text className='font-semibold font-size-text-md'>
                            {t('navbar.impressum')}
                            </Text>
                        </Link>
                </div>
                <div className="pt-4">
                    <Text className='font-size-text-md line-height-text-md text-center'>&#169; {t("footer.rightsMessage")}</Text>
                </div>
            </div>
        </footer>
        </>
    )
};

export default Footer;