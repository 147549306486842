import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './pages/Homepage';
import './translation/i18n'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import ScrollToAnchor from './components/common/ScrollToAnchor';
import AppContext from './context/appContext';
import ImpressumPage from './pages/Impressum';
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navbar />,
    children: [
      {
        path: "/",
        element: <HomePage/>,
      }
    ]
  }
]);
const App = () => {
  const [navOpen, setNavOpen] = useState(false)
  return (
    <AppContext.Provider value={{navOpen, setNavOpen}}>
      <Router>
        <Navbar/>
        <div className=''>
        <ScrollToAnchor/>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />}/>
          <Route path="/imprint" element={<ImpressumPage />}/>
        </Routes>
        </div>
        <Footer/>
        {navOpen &&
          <div  className='fixed'
          onClick={() => setNavOpen(false)}
          style={{
            height: "100vh",
            width: "100vw",
            top: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}>
          </div>
        }
      </Router>
    </AppContext.Provider>
  )
}
root.render(
  <App/>
);

reportWebVitals();
