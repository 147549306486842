const WrappedButton = ({ children, onClick, style, className, href, variant}) => {
    if (variant === "button" ){
        return <button className={className} onClick={onClick}>{children}</button>
    }
    return (
        
    <a onClick={onClick} style={style} className={className} href={href}>
        {children}
    </a>
    )
};

export default WrappedButton;