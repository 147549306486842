import {useTranslation} from "react-i18next";
import SectionHeading from "../../common/SectionHeading";
import SingleCertificateV2 from "./SingleCertificateV2";

const CertificationSection = () => {
    const {t} = useTranslation();
    const logos = [
        "/img/certifications/ce-cert.png",
        "/img/certifications/iso27001-cert.png",
        "/img/certifications/iso13485-cert.png"
    ]
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9 flex flex-col" id='certification-section'>
            <SectionHeading
                upperHeader={t('certification.upperHeader')}
                header={t('certification.header')}
                subHeader={t('certification.subHeader')}
            />
        
            <section className="flex gap-x-[24px] gap-y-[24px] justify-center pt-5 flex-col  md:flex-row">

                {t('certification.certificats').map((item, index) => {
                        return (
                            <SingleCertificateV2
                                key={index}
                                text={item.paragraph}
                                image={logos[index]}
                            />
                        );
                    })}
                </section>
        </section>
    )
};

export default CertificationSection;