import { useTranslation } from "react-i18next";
import SectionHeading from "../../common/SectionHeading";
import SingleContactInfo from "./SingleContactInfo";
import MessageChatCircle from "@untitled-ui/icons-react/build/cjs/MessageChatCircle";
import MarkerPin02 from "@untitled-ui/icons-react/build/cjs/MarkerPin02";


const ContactFormSection = () => {
    const {t} = useTranslation();
    
    return (
        <section className="pt-5 pb-5 md:pt-7 md:pb-7" id='contact-section'>
            <div className="flex gap-x-[32px] flex-col md:flex-row gap-y-10 md:gap-y-0">
                <div className="flex-1" >
                    <SectionHeading
                        upperHeader={t('contact.upperHeader')}
                        header={t('contact.header')}
                        subHeader={t('contact.subHeader')}
                        classesName={"!text-left"}
                        />
                </div>
                <div className="flex-1  gap-4">
                    <SingleContactInfo header={t('contact.information-1.header')} paragraph={t('contact.information-1.paragraph')}>
                        <MessageChatCircle className="text-white self-center"/>    
                    </SingleContactInfo>
                    <SingleContactInfo className={'mt-4'} header={t('contact.information-2.header')} paragraph={t('contact.information-2.paragraph')}>
                        <MarkerPin02 className="text-white self-center"/>
                    </SingleContactInfo>
                </div>
            </div>
        </section>
    );
};

export default ContactFormSection;