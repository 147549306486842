import Header from "../../common/Header"
import Text from "../../common/Text"

const SingleCertificateV2 = ({text, image="/img/docjo-icon.svg"}) => {
    return (
        <div className="text-center max-w-[380px] w-full self-center md:self-start">
            <section className="justify-center border pt-3 pb-3 mb-2 md:mb-3 pl-4 pr-4 rounded-[8px] w-full">
                <div className="flex justify-center">
                    <img loading="lazy" src={image} className="cursor-pointer h-[120px]"></img>
                </div>
            </section>
            <Text variant="span" className='font-size-text-xl font-semibold line-height-text-xl text-center'>{text}</Text>
        </div>
    )
}

export default SingleCertificateV2;