import Header from "./Header";
import Text from "./Text";
const SectionHeading = ({upperHeader, header, subHeader, children, classesName}) => {
    return (
        <section className={`text-center max-w-screen-xl justify-self-center mx-auto my-0 ${classesName}`}>
                <Text variant="span" className='font-size-text-md text-secondary-500 font-semibold line-height-text-md uppercase'>{upperHeader}</Text>
                <Header className=' mt-1 md:mt-1 font-size-display-md font-semibold line-height-display-md' variant="h2">{header}</Header>
                <div class='max-w-screen-lg'>
                    {subHeader && <Text className='font-size-text-lg mt-[16px] md:mt-[24px] line-height-text-xl max-w-screen-xl'>{subHeader}</Text>}
                    </div>
                {children}
        </section>
    );
};

export default SectionHeading;