import { useCallback, useContext } from 'react';
import Header from '../../common/Header';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

import "../../../App.scss"
import AppContext from '../../../context/appContext';
const CompanyCarouselSection = () => {
    const {t} = useTranslation();
    const images = ["astraZeneca.png", "basel_uni.png", "eth_img.png",
         'sheba.png', 'spiFa_img.png', 'zurrich_uni.png'];
    let options = { loop: true }
    const [emblaRef, emblaApi] = useEmblaCarousel(  {
        loop: true,
        dragFree: true,
        align: 'center',
      },
      [Autoplay({ playOnInit: true, delay: 3000 })])
      const toggleAutoplay = useCallback(() => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
    
        const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play
        playOrStop()
      }, [emblaApi])

    const {navOpen} = useContext(AppContext);
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9  company-carousel">
            <div className='container'>

            <Header className='text-center font-size-text-md font-medium mb-4 text-dark-secondary'>{t('carousel.header')}</Header>
            <div className={`embla ${navOpen ? '':'embla-container'}`} ref={emblaRef}>
                <div className="embla__container gap-6">
                    {images.map((item, index) => {
                        return (
                            <div className='embla__slide flex justify-center content-center' key={index}>
                                    <img 
                                        loading="lazy" 
                                        src={`/img/companies/${item}`}
                                        className="cursor-pointer w-60 h-auto object-contain"
                                        />
                                </div>
                            );
                        })}
                </div>
            </div>
                        </div>
        </section>
    );
};
export default CompanyCarouselSection;