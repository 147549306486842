import { useTranslation } from "react-i18next";
import SectionHeading from "../../common/SectionHeading";
import SingleProduct from "./SingleProduct";

const ProductSection = () => {
    const {t, i18n} = useTranslation();
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9 flex flex-col gap-y-[64px] md:gap-y-[96px]" id="product-section" >
            <SectionHeading 
                upperHeader={t('products.upperHeader')}
                header={t('products.header')}
                subHeader={t('products.subHeader')}
            />
            <SingleProduct
                header={t('products.product-1.header')}
                paragraph={t('products.product-1.subHeader')}
                features={t('products.product-1.features')}
                buttonText={t("products.product-1.buttonText")}
                key="product-1"
                imagePath={`/img/products/velaria-${i18n.language !=='de' ? "eng" : "de"}.png`}
                mobileImagePath={`/img/products/valeria-mobile.jpg`}

            />
            <SingleProduct
                header={t('products.product-2.header')}
                paragraph={t('products.product-2.subHeader')}
                features={t('products.product-2.features')}
                buttonText={t("products.product-2.buttonText")}
                reversedClass="md:flex-row-reverse"
                key="product-2"
                imagePath={`/img/products/diga1-${i18n.language !=='de' ? "eng" : "de"}.png`}
                mobileImagePath={`/img/products/diga-mobile.jpg`}
            />
        </section>
    );
};

export default ProductSection;