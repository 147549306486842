import Text from "../../common/Text";
import Header from "../../common/Header";
import WrappedButton from "../../common/Button";
import CheckCircle from "@untitled-ui/icons-react/build/cjs/CheckCircle";
import MessageChatCircle from "@untitled-ui/icons-react/build/cjs/MessageChatCircle";
import "../../../App.scss"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
const SingleProduct = ({header, paragraph, features=[], buttonText, buttonClassName, imagePath='/img/camera-plus.svg', reversedClass="", mobileImagePath}) => {
   const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const isVelaria = imagePath.toLowerCase().includes('velaria');

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
    img.src = imagePath;
  }, [imagePath]);

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: dimensions.height,
  };

  const imageStyle = {
    position: 'absolute',
    height: '100%',
    width: 'auto',
    top: 0,
    [isVelaria ? 'left' : 'right']: 0,
    objectFit:'cover',
    objectPosition: isVelaria !== true ? 'left' : 'right'
  };
    return (
        <section className={`flex gap-x-32 gap-y-12 md:gap-y-0   ${reversedClass ? `${reversedClass} flex-col-reverse`: "flex-col-reverse md:flex-row"} `}>
                <section className="flex-1 flex justify-center relative  content-center">
                
                <img
                  loading="lazy"
                  src={imagePath}
                  style={imageStyle}
                  className="hidden md:block"
                  alt="Dynamic cropped image"
                />
                <img
                  loading="lazy"
                  src={mobileImagePath}
                  className=" md:hidden"
                  alt="Dynamic cropped image"
                />
                    
                </section>
                <section className="flex-1 pt-4 pb-4">
                    <Header className="font-size-display-sm font-semibold line-height-display-sm ">{header}</Header>
                    <Text className="pt-[16px] md:pt-[20px] font-size-text-lg line-height-text-lg">{paragraph}</Text>
                    <ul className="pb-4 pt-4">

                        {features.map((item, index) => {
                            return (
                                <li className="flex items-center pt-[16px] md:pt-[20px]" key={index}>
                                    <div className="self-start ml-1 md:ml-2">
                                        <CheckCircle
                                            size={"48"}
                                            className={`${reversedClass !=="" ? 'text-primary-500' : 'text-secondary-500'}`}
                                            />
                                    </div>
                                    <Text className="font-size-text-lg ml-2 h-100">{item}</Text>
                                </li>
                            )
                        })}
                    </ul>
                    <div>
                    <WrappedButton onClick={() => {}} className={`link-button ${reversedClass !=="" ? 'bg-primary-500' : 'bg-secondary-500'}`}>
                       
                       <Link to="#contact-section">
                        {buttonText}
                       </Link>
                    </WrappedButton>
                    </div>
                </section>
        </section>
    );
}

export default SingleProduct;