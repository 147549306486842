import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Header from "../../common/Header"
import Text from "../../common/Text"

const SingleSolutionV2 = ({header, paragraph, circuralClass='diga', progress='55', circurallColor='', curveText}) => { 
    const percentage = '55'
    return (
        <section className='flex-1'>
            <div className="flex-1 text-center bg-grey-400">
                <div>
                    <CircularProgressbar 
                        value={progress}
                        text={`${progress}%`}
                        className={`h-[120px] w-auto ${circuralClass}`}
                        strokeWidth={12}
                        styles={buildStyles ({
                            pathColor: `${circurallColor}`,
                            textColor: '#1D0900'
                        })}
                        />
                </div>
                <Header variant="h3" className="font-semibold font-size-text-xl mt-[16px] md:mt-[20px]">{header}</Header>
                <Text className="ont-size-text-md g mt-[8px] min-h-[40px]">{paragraph}</Text>
            </div>
            <div className='mt-2 text-center'>

                <Text variant='span' className={'font-size-text-md text-textSecondary-400 italic font-thin	'}>{curveText}</Text>
            </div>
        </section>
    )
};

export default SingleSolutionV2;