import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import WrappedButton from "./common/Button";
import "../App.scss"
import Text from "./common/Text";
import ChevronDown from "@untitled-ui/icons-react/build/cjs/ChevronDown";
import { useEffect, useContext } from "react";
import AppContext from "../context/appContext";

const Navbar = () => {
    const {t, i18n} = useTranslation();
    const languages = ["EN", "DE"]

    useEffect(() => {
        if (window.location.hash) {
           const element = document.querySelector(window.location.hash)
           if (element) {
             element.scrollIntoView({ behavior: 'smooth' })
           }
          }
    }, [window.location.hash])
    
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };
    const {navOpen, setNavOpen} = useContext(AppContext);
    return ( 
        <>
            <nav className={`fixed md:sticky w-full pt-[18px] pb-3 md:pb-[18px] top-0 z-20 bg-white transition-transform duration-300 translate-y-0
            `
            }>         
                <div className="justify-between container hidden md:flex">

                    <section className="flex">
                    <div className="content-center">
                        <Link to="/">
                            <img loading="lazy" src={'/img/docjo-icon.svg'} className="cursor-pointer w-[103px] h-[43px] logo"></img>
                        </Link>
                    </div>
                    <Link className={"ml-[42px] self-center cursor-pointer"} to="/#solution-section">
                        <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                            {t('navbar.solutions')}
                        </Text>
                    </Link>
                    <Link className={"ml-4 self-center cursor-pointer"} to="/#product-section">
                        <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                        {t('navbar.product')}
                        </Text>
                    </Link>
                    <Link className={"ml-4 self-center cursor-pointer"} to="/#certification-section">
                        <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>    
                        {t('navbar.certifications')}
                        </Text>
                    </Link>
                    <Link className={"ml-4 self-center cursor-pointer"} to="/#team-section">
                        <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                            {t('navbar.team')}
                        </Text>
                    </Link>
                    </section>
                    <section className="flex gap-x-[12px]">
                        <Menu as="div" className="relative inline-block text-left content-center">
                            <div>
                                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md border-none bg-white self-center text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 shadow-none">
                                    <Text variant="span" className='font-semibold font-size-text-md self-center'>
                                        {languages.includes((i18n.language).toUpperCase()) ? (i18n.language).toUpperCase() : "EN" }
                                    </Text>
                                <ChevronDown className="self-center mt-[1px]"/>
                                </MenuButton>
                            </div>
                            <MenuItems
                                transition
                                className="absolute right-0 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                <div className="py-1">
                                <MenuItem>
                                    <a
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    onClick={() => handleLanguageChange('de')}
                                    >
                                    {t('navbar.de')}
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                    onClick={() => handleLanguageChange('en')}
                                    >
                                    {t('navbar.eng')}
                                    </a>
                                </MenuItem>
                                </div>
                            </MenuItems>
                        </Menu>
                        <WrappedButton onClick={() => {}} className={'link-button bg-secondary-500'}href='/#contact-section'>
                            <Text variant="span" className='line-height-text-md'>
                                {t('navbar.contactButton')}
                            </Text>
                        </WrappedButton>
                    </section>
                </div>
                <div className="container flex md:hidden flex-col gap-y-6 z-10">
                    <div className=" flex flex-row justify-between">
                        
                        <div className="content-center">
                            <img loading="lazy" src={'/img/docjo-icon.svg'} className="cursor-pointer w-[103px] h-[43px] logo"></img>
                        </div>
                        <button onClick={() => setNavOpen(!navOpen)} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p- w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden dark:text-gray-400" aria-controls="navbar-default" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            {navOpen ? (
                                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            ) : (
                                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )}
                        </button>
                    </div> 
                    {navOpen && 
                        <div className="relative">
                            <div className="flex flex-col w-full">
                                <Link className={"cursor-pointer pt-[12px] pb-[12px]"} to="/#solution-section">
                                    <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                                        {t('navbar.solutions')}
                                    </Text>
                                </Link>
                                <Link className={"cursor-pointer pt-[12px] pb-[12px]"} to="/#product-section">
                                    <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                                    {t('navbar.product')}
                                    </Text>
                                </Link>
                                <Link className={"cursor-pointer pt-[12px] pb-[12px]"} to="/#certification-section">
                                    <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>    
                                    {t('navbar.certifications')}
                                    </Text>
                                </Link>
                                <Link className={"cursor-pointer pt-[12px] pb-[12px]"} to="/#team-section">
                                    <Text variant="span" className='font-semibold font-size-text-md line-height-text-md'>
                                        {t('navbar.team')}
                                    </Text>
                                </Link>
                            </div>
                            <div class="fixed left-0 right-0 h-[1px] bg-borderFaq-400"></div>

                            <div className="flex flex-row pt-3 gap-x-3">
                                <Menu as="div" className="relative inline-block text-left content-center">
                                    <div>
                                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white self-center text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 shadow-none">
                                        <Text variant="span" className='font-semibold font-size-text-md'>{languages.includes((i18n.language).toUpperCase()) ? (i18n.language).toUpperCase() : "EN" }</Text>
                                        <ChevronDown className=""/>
                                        </MenuButton>
                                    </div>
                                    <MenuItems
                                        transition
                                        className="absolute left-[-16px] z-10  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                        <div className="py-1">
                                        <MenuItem>
                                            <a
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                            onClick={() => handleLanguageChange('de')}
                                            >
                                            {t('navbar.de')}
                                            </a>
                                        </MenuItem>
                                        <MenuItem>
                                            <a
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                            onClick={() => handleLanguageChange('en')}
                                            >
                                            {t('navbar.eng')}
                                            </a>
                                        </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Menu>
                                <WrappedButton onClick={() => {}} className={'link-button bg-secondary-500 w-full text-center'}href='/#contact-section'>
                                    <Text variant="span" className='line-height-text-md'>
                                        {t('navbar.contactButton')}
                                    </Text>
                                </WrappedButton>
                            </div>
                             
                        </div>
                    }
                   
                </div>
              
            </nav>
        </>  
    )
};

export default Navbar;