import { useTranslation } from "react-i18next";
import SectionHeading from "../components/common/SectionHeading";
import Header from "../components/common/Header";
import Text from "../components/common/Text";
import FormalSection from "../components/privacyPolicy/FormalSection";

const PrivacyPolicyPage = () => {
    const {t} = useTranslation();

    return (
        <section className="container">
            <SectionHeading
                header={t("privacyPolicy.header")}
                subHeader={t("privacyPolicy.subHeader")}
                classesName={'pt-9 pb-9'}
                >
                    <section className="mt-[192px]">

                        {t("privacyPolicy.paragraphs").map((item, index) => {
                            return (
                                <FormalSection formal={item} key={index}/>
                            )
                        })}
                    </section>
                </SectionHeading>

        </section>
    );
};

export default PrivacyPolicyPage;