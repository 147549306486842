import { useTranslation } from "react-i18next";

import SectionHeading from "../../common/SectionHeading";
import SingleSolution from "./SingleSolution";
import LineChartDown03 from "@untitled-ui/icons-react/build/cjs/LineChartDown03";
import Database01 from "@untitled-ui/icons-react/build/cjs/Database01";
import ActivityHeart from "@untitled-ui/icons-react/build/cjs/ActivityHeart";
import Users03 from "@untitled-ui/icons-react/build/cjs/Users03";
import SingleSolutionV2 from "./SingleProblemV2";

const ProblemSection = () => {
    const {t} = useTranslation();
    const problemIcons = [
        <Users03/>,
        <ActivityHeart/>,
        <LineChartDown03/>,
    ]
    const colors = [
        "#C24B36",
        "#2D939A",
        "#200A09"
    ]
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9" id="solution-section">
            <div className="container">

            <SectionHeading 
                upperHeader={t('problem.upperHeader')}
                header={t('problem.header')}
                // subHeader={t('problem.subHeader')}
                />
            
            <section className="flex w-100 mt-[64px] gap-x-16 flex-col md:flex-row gap-y-10 color-red-600">
                {t('problem.solutions').map((item, key) => {
                    return (
                        <SingleSolutionV2 
                        key={key}
                        header={item.header}
                        paragraph={item.text}
                        progress={item.progress}
                        curveText={item.paragraph}
                        circuralClass={key === 1 ? 'velaria':''}
                        circurallColor={colors[key]}
                        >{problemIcons[key]}</SingleSolutionV2>
                    ) 
                })}
            </section>
        </div>   
        </section>
    );
};

export default ProblemSection;